<template>
  <div class="container">
    <h4 class="text-center my-4">Мое расписание</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">

      <div class="row">
        <div v-for="(day, dayIndex) in weekdays" :key="dayIndex" class="mb-4 border col-md-2">
          <div class="text-center bg-violet fs-5 mb-3">{{day.name_ru}}</div>
          <div v-for="(scheduleRow, scheduleRowIndex) in scheduleTable[day.id]" :key="'s'+scheduleRowIndex"
               class="mb-3">
            <div class="fw-bold">{{scheduleRow.time}}</div>
            <div>{{scheduleRow.education_discipline_name}}</div>
            <div>{{scheduleRow.education_type_name}}</div>
            <div>Ауд.: {{scheduleRow.room}}</div>
          </div>
        </div>
      </div>

    </div>


  </div>
</template>

<script>


  import {mapActions, mapState, mapMutations} from 'vuex'
  import httpClient from "@/services/http.service"


  export default {
    name: 'MySchedule',
    data() {
      return {
        loading: true,
        scheduleTable: {},
      }
    },
    computed: {
      ...mapState('managerSchedule', ['days']),
      weekdays() {
        return this.days.filter(d => d.id < 6)
      }
    },
    methods: {
      ...mapActions('managerSchedule', ['GET_DAYS']),
      ...mapMutations(['SET_ERROR']),
      async getSchedule() {
        const schedule = await this.getMySchedule()

        const scheduleTable = {}
        for (let day of this.days) {
          scheduleTable[day.id] = schedule.filter(i => i.day_id == day.id)
        }

        this.scheduleTable = scheduleTable
      },
      async getMySchedule() {
        try {
          const {status, data} = await httpClient.get(`schedule/schedule/get-schedule`)
          if (status === 200) {
            return data
          }
        } catch (e) {
          this.SET_ERROR(e)
        }
        return []
      },
    },
    async mounted() {
      await this.GET_DAYS()

      await this.getSchedule()

      this.loading = false
    },
  }


</script>


<style scoped>
  .w160 {
    width: 160px;
  }

  .w100p {
    width: 100%;
  }

  .text-violet {
    color: #444791;
  }

  .bg-violet {
    background-color: #e8ebfa;
  }

  .border-left-violet {
    border-left: 5px solid #5b5fc7;
  }

</style>
